import Header from './Header';
import Footer from './Footer';
import { Link } from 'react-router-dom';
import '../style.css';
import Formulaire from './Formulaire';
import Formulaire_EcranLarge from './Formulaire_EcranLarge';
import Header_EL from './Header_EL';
import '../stylecontact.css';
import FooterContact from './FooterContact'
import React, { useEffect } from 'react';
import '../styles_headers.css'

export default function Contact() {

  useEffect(() => {
    document.title = "Contact";
  }, []);


  return (
    <>
    <div className='pageContactPortrait'>
      <div className="containerHeaderContact">
        <Header />
      </div>
      <div className="containerMainContact">
      <div className='blocImageContact'> 
        <img src='./images/imagepagecontact.jpeg' className='contactPagePortrait'/>
      </div>
        <Formulaire/>
      </div>
      <div><FooterContact/></div>
      
      <div className="grid-dmobile ">
        <div className='centrageLink'>
          <Link to="/Mentionslegales" className='mentionsLegalesStylePortrait'>
            MENTIONS LEGALES
          </Link>
        </div>
      </div>
      </div>


{/*version paysage:----------------------------------------------- */}



          <div className='grid-container-contact-EcranLarge'> 
          <div className='grid-container-contact-EcranLargeEnfant'>
          <Header_EL />      
          <div className="contactMainbloc">
            <Formulaire_EcranLarge/>
              </div> <div className="ligneMentionLegales">
                <div className='centrageLink'>
                  <Link to="/Mentionslegales"  className='mentionslegalesStylecontact'>
                    MENTIONS LEGALES
                  </Link>
                </div>
              </div> 
          </div>
  


            




         </div>

          </>
  );
}
