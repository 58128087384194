import React from 'react';

export default function Formulaire_EcranLarge() {
  // Fonction pour générer l'adresse e-mail
  function generateEmail() {
    const username = "baziredavid88";
    const domain = "gmail.com";
    return `${username}@${domain}`;
  }

  // Fonction pour gérer le clic sur le lien de contact
  const handleContactClick = () => {
    const email = generateEmail();
    window.location.href = `mailto:${email}`;
  };

  return (
    <div className="blocContactParent">
      <div className='blocImageContact'> 
      <img src='./images/imagepagecontact.jpeg' className='imgconatctpage'/>
</div>

<div className="blocContactEnfant">
  <div className="blocContactSousEnfant">
   
      <div className="blocContactTitreh1"><h1>Contactez moi</h1></div>
      
      <p>Vous avez des questions ou besoin d'un devis personnalisé ? Je suis à votre entière disposition pour vous apporter toutes les informations dont vous pourriez avoir besoin. 
              N'hésitez pas à me contacter, et je m'engage à vous répondre dans les plus brefs délais. Votre projet mérite une attention particulière, et je suis là pour vous assurer
               un service sur mesure. 
              Vous pouvez me contacter avec ce lien mail.
</p>
<div className='centrageLinkContact'>
    <a href="#" onClick={handleContactClick} className='linkEffectContact'>
      &gt; Contacter moi &lt; 
      </a>
</div>
    </div>
</div>

     
    
    </div>
  );
}
