import BtnFooter from './Modele_bouton_menu_mobile';
import '../style.css';
import { Link } from 'react-router-dom';
import MentionsLegales from "./MentionsLegales";
import '../styleFooterResponsive.css';
import { motion } from 'framer-motion';

export default function Footer(){




 // Définition des variants pour l'animation
 const variants = {
    initial: { 
        scale: 1, 
        backgroundColor: "rgba(137, 43, 226, 0)", // couleur initiale transparente
        
       
       
    },
    clicked: {  
        
        scale: 0.9, 
        transition: { duration: 0.05 } ,


 
    }
};


    return(
     <>

                <div className='blocFooterecranmini '>


               
      
                <Link to="/Prestations">
                  <div className='linfootermini'><div className='btnsmalltest'> 
                     <motion.div
                       className="linfootermini"
                       variants={variants}
                       initial="initial"
                       whileTap="clicked"
                       style={{ transform: 'translateX(10%)', marginTop: '1%', height: 'auto' }}>
                {/* Insérez ici le contenu de votre bloc, par exemple une image */}<img src='/icon_prestations.png'/>PRESTATION</motion.div></div>
                  </div>  
                </Link>

          




                <Link to="/Galerie">
                  <div className='linfootermini2'>
                    <div className='btnsmalltest'> 
                    <motion.div
                       className="linfootermini"
                       variants={variants}
                       initial="initial"
                       whileTap="clicked"
                       style={{ transform: 'translateX(40%)', marginTop: '1%', height: 'auto' }}>
                {/* Insérez ici le contenu de votre bloc, par exemple une image */}
                <div className='linfootermini'><img src='/icon_galerie.png'/>GALERIE
                </div>   
                    </motion.div>
                    </div>
                  </div>
                </Link>


                <Link to="/Contact">
                  <div className='linfootermini3'><div className='btnsmalltest'> 
                    <motion.div
                       className="linfootermini"
                variants={variants}
                initial="initial"
                whileTap="clicked"
                style={{  transform: 'translateX(80%)', marginTop: '1%', height: 'auto' }}
            >
                {/* Insérez ici le contenu de votre bloc, par exemple une image */}
      
                <div className='linfootermini'><img src='/icon_contact.png'/>CONTACT</div>   </motion.div>
                </div>
                </div>
                </Link>
                        
                        </div>
                      
</>





    )
}
